export default function applySharedSections(sections = [], sharedSections = []) {
  if (!sections) {
    return []
  }

  const parseJson = ({ json, ...section }) => ({
    ...section,
    ...JSON.parse(json || '{}')
  })

  const sharedSectionsMap = {}
  sharedSections.forEach(({ name, section, json }) => {
    sharedSectionsMap[name] = json ? JSON.parse(json || '{}') : section
  })

  const applyShared = section => {
    const sharedSection = sharedSectionsMap[section.section]
    return sharedSection ? { ...sharedSection, ...section, type: sharedSection.type, id: sharedSection.id || section.id } : section
  }

  return sections
    .map(parseJson)
    .map(applyShared)
    .flatMap(obj =>
      obj.type
        ? obj
        : Object.entries(obj)
            .filter(([key]) => !isNaN(key))
            .map(([_, val]) => val)
    )
}
