import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../layout'
import PageContent from '../components/content/PageContent'
import PageSection from '../components/PageSection'
import Section from '../components/Section'
import PageHead from '../layout/PageHead'
import PageHeader from '../components/Sections/PageHeader'
import HeroSection from '../components/Sections/HeroSection'
import applySharedSections from '../utils/shared-sections.js'

export default function Page({ data: { page, sharedSections, images } }) {
  const sections = applySharedSections(page.sections, sharedSections.nodes)

  return (
    <Layout {...page}>
      <PageHead {...page} />
      <PageHeader />
      {page.hero && <HeroSection {...page.hero} images={images} />}
      {page.body && (
        <Section>
          <PageContent children={page.body} />
        </Section>
      )}

      {(sections || []).map((section, i) => {
        return <PageSection key={i} section={section} images={images} sections={sections} />
      })}
    </Layout>
  )
}

export const query = graphql`
  query Page($id: String!, $directories: [String]!) {
    page: pagesYaml(id: { eq: $id }) {
      title
      path
      canonical_link
      description
      hero {
        text
        classes
        background
        asset
        video_mp4
        poster
      }
      sections {
        id
        type
        json
      }
    }

    sharedSections: allSectionsYaml {
      nodes {
        id
        name
        json
      }
    }

    images: allImageSharp(filter: { fields: { parentDir: { in: $directories } } }) {
      nodes {
        fluid {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
Page.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string.isRequired,
      canonical_link: PropTypes.string,
      description: PropTypes.string,
      hero: PropTypes.object,
      sections: PropTypes.array
    }).isRequired
  }).isRequired
}
