import React from 'react'
import loadable from '@loadable/component'
import { isMobile } from 'react-device-detect'
import Markdown from 'markdown-to-jsx'
import Button from '../Inlines/Button'
import Image from '../Image'
import classNames from 'classnames/bind'
import styles from './hero-section.module.sass'

// import ContactForm from '../ContactForm/ContactForm'
const LazyContactForm = loadable(() => import('../ContactForm/ContactForm'))
const ContactForm = props => <LazyContactForm {...props} />

export default function HeroSection({ text, asset, background, classes, images, video_mp4, poster }) {
  const cx = classNames.bind(styles)
  const className = cx('heroSection', classes && classes.split(' '))
  return (
    <section className={className}>
      <div className="container">
        <div className={classNames('row', styles.heroRow)}>
          {text && (
            <Markdown
              className={classNames('col', styles.heroContent)}
              options={{
                forceBlock: true,
                forceWrapper: true,
                overrides: {
                  Button
                }
              }}
            >
              {text}
            </Markdown>
          )}
          {asset && (
            <Markdown
              className={classNames('col', styles.heroAsset)}
              options={{
                forceBlock: true,
                forceWrapper: true,
                overrides: {
                  ContactForm
                }
              }}
            >
              {asset}
            </Markdown>
          )}
        </div>
      </div>
      {(background || video_mp4 || poster) && (
        <figure className={classNames(styles.heroBg, { 'has-video': video_mp4 })}>
          {(background || poster) && <Image src={isMobile ? background : poster || background} loading="eager" images={images} />}

          {video_mp4 && !isMobile && (
            <video loop autoPlay muted>
              <source src={video_mp4} type="video/mp4" />
            </video>
          )}
        </figure>
      )}
    </section>
  )
}
